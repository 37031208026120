@import '@/assets/scss/_variables.scss';

.RoundButton_root {
  cursor: pointer;
  display: flex;
  align-items: center;
  .RoundButton_button {
    width: 12px;
    height: 12px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    background: #2af3f3;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: $tablet-width) {
      width: 26px;
      height: 26px;
      display: flex;
    }
  }
  span {
    margin-left: 6px;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #2af3f3;
    letter-spacing: 0.04em;
    vertical-align: middle;
    @media only screen and (min-width: $tablet-width) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  svg {
    height: 6px;
    @media only screen and (min-width: $tablet-width) {
      height: auto;
    }
  }
}
