@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

































































































@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import './ProviderLimits.scss';
.ProviderLimits_tableContainerSubtitle {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 0 16px;
  height: 40px;
}
