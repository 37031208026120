.ProviderLimits_table {
  width: 100%;
  box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
  .providerManyPayments {
    tr:not(:first-child) {
      td {
        &:first-child {
          padding: 0 0 0 44px;
        }
        // &:last-child {
        //   padding: 0 44px 0 0;
        // }
      }
      .ProviderLimits_tableContainer{
        padding: 0;
      }
    }
  }
  tbody {
    .ProviderLimits_tableContainerSubtitle {
      border-top: solid 1px #236278;
    }
    .providerSingleType {
      .ProviderLimits_tableContainer, .ProviderLimits_tableContainerBody {
        border-top: solid 1px #236278;
      }
    }
    &:nth-child(2) {
      .ProviderLimits_tableContainer, .ProviderLimits_tableContainerBody {
        border-top: none;
      }
    }
  }
  &Icon {
    margin: 0 6px 0 0;
  }

  &Container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  tr {
    &:last-child {

      .ProviderLimits_tableContainer,
      .ProviderLimits_tableContainerBody {
        border-bottom: none;
      }
    }

    &.ProviderLimits_tableHeader {
      background: #2d4d70;
      box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.14);
    }

    th {
      &:nth-child(2) {
        .ProviderLimits_tableContainer {
          justify-content: flex-start;
          padding: 0 0 0 20px;
        }
      }

      .ProviderLimits_tableContainer {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }

      &:first-child {
        .ProviderLimits_tableContainer {
          padding: 0 0 0 16px;
          justify-content: flex-start;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.04em;
          color: #fff;
          height: 40px;
        }
      }

      &:last-child {
        .ProviderLimits_tableContainer {
          justify-content: flex-end;
          padding: 0 60px 0 0;
        }
      }
    }

    td {
      background: #203955;

      .ProviderLimits_tableContainer {
        border-bottom: dashed 1px #236278;
      }

      &:last-child {
        .ProviderLimits_tableContainerBody {
          align-items: flex-end;
          padding: 0 16px 0 0;
        }
      }

      &:nth-child(3) {
        .ProviderLimits_tableContainerBody {
          align-items: center;
        }
      }

      &:first-child {
        .ProviderLimits_tableContainer {
          justify-content: flex-start;
          padding: 0 0 0 16px;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: #fff;
          text-transform: capitalize;
        }
      }

      .ProviderLimits_tableContainer {
        height: 40px;

        &Body {
          height: 40px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border-bottom: dashed 1px #236278;
          justify-content: center;

          &Inner {
            max-width: 100px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            text-align: left;
            margin: 0 auto;
          }

          span {
            font-size: 12px;
            line-height: 14px;
            b {
                font-weight: 400;
                color: #A6AEBF;
            }

            &:first-child {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}