@import '@/assets/scss/_mixins.scss';
@import '@/assets/scss/_variables.scss';
.SquareCoinIcon_root {
  --side: 26px;
  background: rgba(#2af3f3, 0.06);
  border-radius: 2px;
  width: var(--side);
  height: var(--side);
  position: relative;
  display: inline-block;
  text-align: center;
  @media only screen and (min-width: $large-width) {
    --side: 30px;
  }
}

.SquareCoinIcon_wrapper {
  --diametr: 18px;
  width: var(--diametr);
  height: var(--diametr);
  color: #2d4d70;
  @include centeringWithTransform;
  border-radius: 50%;
  background-color: #2af3f3;
  box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: $large-width) {
    --diametr: 24px;
  }
  svg {
    display: block;
    --diametr: 60%;
    width: var(--diametr);
    height: var(--diametr);
  }
}
