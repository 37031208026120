@import '@/assets/scss/_variables.scss';
.Limits_root {
    height: calc(100vh - 20px - 64px - 20px);
}
.Limits_wrapper {
    height: 100%;
}
.ProvidersInfoTable {
    text-align: left;
    --icon-side: 22px;
    --icon-cell-width: 44px;
    font-size: 12px;
    line-height: 14px;
    background: rgba(#2D4D70, .44);
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
//     padding: 10px;
    @media only screen and (min-width: $mobile-width) {
        display: table;
        width: 100%;
        padding: 0;
    }
    &_Row {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: $mobile-width) {
            display: table-row;
        }
        &--head {
            flex-direction: row;
            background: #2D4D70;
            box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.14);
        }
        &--provider {
            // gap: 6px;
            border-top: 1px solid rgba(#2AF3F3, .21);
            .ProvidersInfoTable_Row--head + & {
                border-top: none;
            }
            @media only screen and (min-width: $mobile-width) {
                border-top: none;
            }
        }
        &--route {
            flex-direction: column;
        }
    }
    &_Cell {
        --cell-display: table-cell;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 30px;
        gap: 10px;
        @media only screen and (min-width: $mobile-width) {            
            height: 42px;
            display: var(--cell-display);
            vertical-align: middle;
            padding-right: 16px;
            padding-left: 0;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
        }
        &:empty {
            padding: 0;
            min-height: 0;
        }
        .ProvidersInfoTable_Row--head & {
            display: none;
            @media only screen and (min-width: $mobile-width) {
                display: var(--cell-display);
            }
        }
        &:first-child {
            @media only screen and (min-width: $mobile-width) {
                padding-left: 16px;
            }
        }
        .ProvidersInfoTable_Row--head & {
            text-transform: uppercase;
            font-weight: 500;
        }
        .ProvidersInfoTable_Row--provider & {
            border-top-color: rgba(#2AF3F3, .21);
        }
        .ProvidersInfoTable_Row--head + .ProvidersInfoTable_Row--provider & {
            border-top-color: transparent;
        }
        .ProvidersInfoTable_Row--route & {
            border-top: 1px dashed rgba(#2AF3F3, .21);
        }
        &--icon {
            white-space: nowrap;
            font-size: 18px;
            line-height: 21px;
            font-weight: bold;
            @media only screen and (min-width: $mobile-width) {
                max-width: var(--icon-cell-width);
                padding-right: 0;
            }     
            .ProvidersInfoTable_Row--head & {
                display: var(--cell-display);
            }       
            .ProvidersInfoTable_Row--route & {
                border-top-color: transparent;
            }
        }
        &--title {
            justify-content: center;
            @media only screen and (min-width: $mobile-width) {
                width: 33.33%;
            }
            .ProvidersInfoTable_Row--provider & {
                line-height: var(--icon-side);
                font-size: 16px;
                //white-space: nowrap;
            }
        }
        &--deposit {            
            @media only screen and (min-width: $mobile-width) {
                width: 33.33%;
            }
        }
        &--withdrawal {
            @media only screen and (min-width: $mobile-width) {
                width: 33.33%;
            }
        }
        &--fees {
            @media only screen and (min-width: $mobile-width) {
                text-align: right;
            }
        }
    }
    &_Icon {
        width: var(--icon-side);
        height: var(--icon-side);
        border-radius: 2px;
        background: #fff;
        padding: 2px 4px;
        overflow: hidden;
        img {
            width:100%;
            height: 100%;
            object-fit: scale-down;
            object-position: center center;
        }
    }
    &_Property {
        //white-space: nowrap;
        &Name {
            color: #A6AEBF;
        }
    }
    &_MobileTitle {
        // color: #A6AEBF;
        text-transform: uppercase;
        font-weight: 500;
        @media only screen and (min-width: $mobile-width) {
            display: none;
        }
    }
}